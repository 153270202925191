<template>
  <div>
    <!-- <patientSelect @onConfirm="patientConfirm" /> -->
    <van-sticky :offset-top="50">
      <div class="page_top">
        <div class="one_item">
          <div class="item_left">
            就诊人
          </div>
          <div class="item_name">
            {{ patientDict.xm }} {{ patientDict.xbmc }} {{ patientDict.age }}岁
          </div>
          <div class="item_right">
            {{ patientDict.patientCardList[0].jzklxmc }} {{ patientDict.patientCardList[0].jzkh }}
          </div>
        </div>
      </div>
    </van-sticky>
    <load-more :data-source="dataList" :total="total" :loading="loading" @load-more="onLoad">
      <prescription-item :data="dataList" type="order" :is-prescription="false" />
    </load-more>
  </div>
</template>
<script>
 import { userModel } from '@/api/user'
 import loadMore from '@/assets/js/mixin/load-more'
export default {
  name:'List',
  mixins: [loadMore],
  data(){
    return{
      patientDict: {},//就诊人字典
      Picker:false,
      patientIdCard:'',
      dataList:[],
      patientId:''
    }
  },
  created(){
    this.patientDict = JSON.parse(this.$store.getters.patient)
    this.patientId = this.patientDict.patientId
    this.patientCardId = this.patientDict.patientCardList[0].patientCardId
  },
  mounted(){
    this.getList()
  },
  methods:{
    getList(){
      let params={
        patientCardId: this.patientCardId,
        patientInfoId: this.patientId,
        page: this.page,
        pageSize: this.pageSize,
        prescriptionId:''
      }
      userModel.prescriptionList(params).then(res=>{
          this.dataList.push(res.data.records)
          this.total = res.data.total
          this.loading = false
      })
    },
    patientConfirm(value){
      this.patientIdCard=value.zjh||""
      this.page=1
      this.dataList=[]
      this.getList()
    },
    onLoad(){
      this.loading=true
      this.page+=1
      this.getList()
    }
  }
}
</script>
<style scoped lang='less'>
.page_top {
  background-color: #1e80ff;
  border-bottom: 1px solid #f5f5f5;

  .one_item {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    padding: 24px 32px;
    color: white;
    border-bottom: 1px solid #f5f5f5;
    font-size: 30px;
  }
}
</style>
